import logo from "./logo.svg";
import React from "react";
import "./App.css";
import Home from "./pages/Home.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import YouthBridgeLogo from "../src/assest/Youth Bridge Small (1).png";

function App() {
  return (
    <Router>
      {/* <div style={{ display: "flex", alignItems: "center" }}>
        <div 
        id="logo"
        >
          <img
            src={YouthBridgeLogo}
            alt="Young Generation"
            style={{
              display: "flex",
              alignItems: "center",
              height: "60px",
              paddingLeft: "40%",
              alignItems: "center",
              paddingTop: "3%",
            }}
          />
        </div>

        <nav>
          <ul
            style={{
              display: "flex",
              listStyle: "none",
              margin: 0,
              padding: 0,
            }}
          >
            <li style={{ marginRight: "10px" }}>
              <a href="/">Home</a>
            </li>
            <li style={{ marginRight: "10px" }}>
              <a href="#about">About</a>
            </li>
            <li style={{ marginRight: "10px" }}>
              <a href="#Events">Events</a>
            </li>
            <li style={{ marginRight: "10px" }}>
              <a href="#Register">Register</a>
            </li>
            <li>
              <a href="#Gallery">Gallery</a>
            </li>
          </ul>
        </nav>
      </div> */}

      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/about" element={<About />} /> */}
        {/* Add routes for other tabs */}
      </Routes>
    </Router>
  );
}

export default App;
