import React from "react";
import { useState, useEffect } from "react";
import bg_temple from "../assest/bg_temple.png";

import bg_temple_2 from "../assest/bg_temple_2.png";
import bg_temple_3 from "../assest/bg_temple_3.png";
import "./Home.css";
import card_1 from "../assest/card_1.png";
import card_2 from "../assest/card_2.png";
import card_3 from "../assest/card_3.png";
import Pragmatiq_logo from "../assest/Pragmatiq_logo.png";
import volunteer_logo from "../assest/volunteer_logo.png";
import ezjobs_logo from "../assest/ezjobs_logo.png";

import card_2_1 from "../assest/card_2_1.png";
import card_2_2 from "../assest/card_2_2.png";
import card_2_3 from "../assest/card_2_3.png";
import card_2_4 from "../assest/card_2_4.png";
import call from "../assest/call1.png";
import mail from "../assest/mail1.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import Venkateswara from "../assest/Venkateshwar_Bridgewater_temple/Venkateshwar_Bridgewater_temple.png";
// import Balaji from "../assest/Venkateshwar_Bridgewater_temple/Balaji Temple white.png";
// import Bridgewater from "../assest/Venkateshwar_Bridgewater_temple/Bridgewater Balaji Temple.png";
import Image from "../assest/Venkateshwar_Bridgewater_temple/Group 14.png";
// import temple1 from "../assest/temple1.png";
// import temple2 from "../assest/temple2.png";
// import temple from "../assest/temple-image.png";
import YouthBridgeImage from "../assest/Youth Bridge Small (1).png";
// import image3 from "../assest/image (3).png"
// import image1 from "../assest/image (1).png";
// import image2 from "../assest/image (2).png";
import image4 from "../assest/templewebsite1.png";
import image5 from "../assest/templewebsite2.png";
import image6 from "../assest/templewebsite.png";
import { HashLink } from "react-router-hash-link";
import '@fortawesome/fontawesome-free/css/all.css';
import aboutus from "../assest/aboutus.png";

import axios from "axios";
import Config from "../pages/config";


// import Slider_1 from "./Slider_1";

//carousel
const images = [
  card_1,

  card_2,
  card_1,

  card_3,
  card_2,
  card_3,
  // Add more image paths as needed
];
// Gallery
// const Gallery = [Venkateswara, Balaji, Bridgewater, temple1, temple2, temple];
const Gallery = [image4, image5, image6, image4, image5, image6];

// image3, image1, image2,
const Home = () => {
  //backgound images change
  const [currentImage, setCurrentImage] = useState(0);
  const [showMoreImages, setShowMoreImages] = useState(false);


  const [navClass, setNavClass] = useState("topnav");

  const toggleNav = () => {
    if (navClass === "topnav") {
      setNavClass("topnav responsive");
    } else {
      setNavClass("topnav");
    }
  };

  const bgimages = [bg_temple, bg_temple_2, bg_temple_3];


  



  const dotsContainerStyle = {
    position: 'absolute',
    bottom: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: "20px  "
  };



  const dotStyle = {
    height: '8px',
    width: '8px',
    margin: '0 5px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '50%',
    display: 'inline-block',
    cursor: 'pointer',
  };

  const activeDotStyle = {
    ...dotStyle,
    backgroundColor: 'rgba(255, 255, 255, 1)',
  };





  // const [user, setUser] = React.useState({
  //   name: "",
  //   email: "",
  //   phone: "",
  //   zipcode: ""
  // });

  // const [response, setResponse] = useState(null);

  // const handleSubmit = async () => {
  //   console.log(user);
  //   const payload = {
  //     name: user.name,
  //     email: user.email,
  //     templateType:"Pragmatiq",
  //   };

  //   try {
  //     const response = await axios.post(
  //       'http://localhost:63541/sendEmail',

  //       payload

  //     );
  //     console.log(response);
  //     console.log(response,"printing");

  //     if (response.status === 200) {
  //       setResponse("Mail Sent Successfully");
  //       setUser({
  //         name: "",
  //         email: "",
  //         phone: "",
  //         zipcode:""
  //       });
  //     } else {
  //       setResponse("Something Went Wrong");
  //     }
  //     console.log(response);
  //     // toast.message('Mail Sent Successfully');
  //   } catch (error) {
  //     console.log(error);
  //     // toast.error('Something Went Wrong');
  //     setResponse("Something Went Wrong");
  //     console.log(error);
  //   }
  // };
  // const [user, setUser] = React.useState({
  //   name: "",
  //   email: "",
  //   phone: "",
  //   zipcode:"",
  // });
  const validateName = (name) => /^[a-zA-Z]+$/.test(name);
  const validatePhoneNumber = (number) => /^\d{10}$/.test(number);
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validateZipcode = (zipcode) => /^\d{6}$/.test(zipcode);


  // const [response, setResponse] = useState(null);




  const [user, setUser] = React.useState({
    name: "",
    email: "",
    phone: "",
  });
 
  const [response, setResponse] = useState(null);
 
  const handleSubmit = async () => {

      const validationErrors = validate();
  //   if (Object.keys(validationErrors).length > 0) {
  //     setErrors(validationErrors);
  //     return;
  //   }




  // const handleSubmit =async ()  => {
  
  //   let valid = true;
  //   const newErrors = { name: '', email: '', phone: '', zipcode: '' };


  //   if (!/^[A-Za-z]+$/.test(user.name)) {
  //     newErrors.name = 'Name should only contain letters';
  //     valid = false;
  //   }

  //   // Email validation
  //   if (!/\S+@\S+\.\S+/.test(user.email)) {
  //     newErrors.email = 'Please enter a valid email';
  //     valid = false;
  //   }

  //   // Phone validation
  //   if (!/^\d{10}$/.test(user.phone)) {
  //     newErrors.phone = 'Phone number should be exactly 10 digits';
  //     valid = false;
  //   }

  //   // Zipcode validation
  //   if (!/^\d{6}$/.test(user.zipcode)) {
  //     newErrors.zipcode = 'Zipcode should be exactly 6 digits';
  //     valid = false;
  //   }

  //   setErrors(newErrors);

  //   if (valid) {
  //     // Form is valid, proceed with submit logic
  //     console.log('Form submitted', user);
  //   }
  
    console.log(user);
    const payload = {
      name: user.name,
      email: user.email,
      templateType:"Purplegene",
    };
 
    try {
      // const response = await axios.post(
      //   `${Config.base_url}/sendEmail`,
      //   payload
      // );
 
      const response = await axios.post('http://localhost:63541/sendEmail', payload);
      if (response.status === 200) {
        setResponse("Mail Sent Successfully");
        setUser({
          name: "",
          email: "",
          phone: "",
        });
      } else {
        setResponse("Something Went Wrong");
      }
      console.log(response);
      // toast.message('Mail Sent Successfully');
    } catch (error) {
      console.log(error);
      // toast.error('Something Went Wrong');
      setResponse("Something Went Wrong");
      console.log(error);
    }
  };
 







  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};
    
    // Validate name
    if (!user.name.trim()) {
      errors.name = "Name is required";
    }
    
    // Validate email
    if (!user.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(user.email)) {
      errors.email = "Email is invalid";
    }
    
    // Validate phone number (must be exactly 10 digits)
    if (!user.phone.trim()) {
      errors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(user.phone)) {
      errors.phone = "Phone number must be exactly 10 digits";
    }
    
    return errors;
  };





  
  // const handleSubmit = async () => {
  //   const validationErrors = validate();
  //   if (Object.keys(validationErrors).length > 0) {
  //     setErrors(validationErrors);
  //     return;
  //   }




  // const handleSubmit =async ()  => {
  
  //   let valid = true;
  //   const newErrors = { name: '', email: '', phone: '', zipcode: '' };


  //   if (!/^[A-Za-z]+$/.test(user.name)) {
  //     newErrors.name = 'Name should only contain letters';
  //     valid = false;
  //   }

  //   // Email validation
  //   if (!/\S+@\S+\.\S+/.test(user.email)) {
  //     newErrors.email = 'Please enter a valid email';
  //     valid = false;
  //   }

  //   // Phone validation
  //   if (!/^\d{10}$/.test(user.phone)) {
  //     newErrors.phone = 'Phone number should be exactly 10 digits';
  //     valid = false;
  //   }

  //   // Zipcode validation
  //   if (!/^\d{6}$/.test(user.zipcode)) {
  //     newErrors.zipcode = 'Zipcode should be exactly 6 digits';
  //     valid = false;
  //   }

  //   setErrors(newErrors);

  //   if (valid) {
  //     // Form is valid, proceed with submit logic
  //     console.log('Form submitted', user);
  //   }
  
  //   console.log(user);
  //   const payload = {
  //     name: user.name,
  //     email: user.email,
  //     templateType: "Purplegene",
  //   };

  //   try {
  //     // const response = await axios.post(
  //     //   `${Config.base_url}/sendEmail`,
  //     //   payload
  //     // );

  //     const response = await axios.post(`${Config.base_url}/sendEmail`, payload);
  //     if (response.status === 200) {
  //       setResponse("Mail Sent Successfully");
  //       setUser({
  //         name: "",
  //         email: "",
  //         phone: "",
  //       });
  //     } else {
  //       setResponse("Something Went Wrong");
  //     }
  //     console.log(response);
  //     // toast.message('Mail Sent Successfully');
  //   } catch (error) {
  //     console.log(error);
  //     // toast.error('Something Went Wrong');
  //     setResponse("Something Went Wrong");
  //     console.log(error);
  //   }
  // };





  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((currentImage + 1) % bgimages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentImage, bgimages]);

  // const goToSlide = (index) => {
  //   setCurrentImage(index);
  // };

  //

  const cardData = [
    {
      id: 1,
      title: "Gurukulam",
      description:
        "A unique platform for knowledge sharing. Share your knowledge or learn from the gurus who are generously lending their time.",
      description_2:
        "“The wealth of knowledge is the most superior wealth of all“.",
      image: card_1,
      button: (
        <div className="seemore">
        <a
          href="https://www.venkateswaratemple.org/CLASSES/temp.main.htm"
          style={{ fontSize: "20px",  width: "30px", color: 'white', textDecoration: 'none', fontFamily: "Montserrat, Medium", color: "blue",marginLeft:"-420%"}}
        >
          see more
          <span style={{ display: 'inline-block', fontSize: "14px", color: 'white', marginLeft: '10px', height: '20px', width: '20px', lineHeight: '20px', textAlign: 'center', verticalAlign: 'middle', color: "blue" }}>
            <i className="fas fa-arrow-right"></i> {/* Font Awesome arrow icon */}
          </span>
        </a>
        </div>
      ),
    },
    {
      id: 2,
      title: "Youth Internships",
      description:
        "Choose an internship program specially created for the temple youth and graciously offered by our community businesses.",
      image: card_2,
      button: (
        <div className="seemore2">
        <a
          href="https://pragmatiq.in/"
          style={{
            fontSize: "20px",
            marginRight: "20px",
            width: "30px",
            color: 'white',
            textDecoration: 'none',
            fontFamily: "Montserrat, Medium",
            // marginRight: "70px",
            marginLeft: "-265%",
            color: "blue"

          }}
        >
          see more

          <span style={{ display: 'inline-block', fontSize: "14px", color: 'white', marginLeft: '10px', height: '20px', width: '20px', lineHeight: '20px', textAlign: 'center', verticalAlign: 'middle', color: "blue" }}>
            <i className="fas fa-arrow-right"></i> {/* Font Awesome arrow icon */}
          </span>
        </a>
        </div>
      ),
    },
    {
      id: 3,
      title: "Youth Volunteering",
      description:
        "Discover the transformative power of youth volunteering, where your commitment becomes a catalyst for positive change in communities.",
      image: card_3,
      button: (
        <div className="seemore">
        <a
          href="https://www.volunteerlocal.com/"
          style={{ fontSize: "20px", width: "30px", textDecoration: 'none', color: 'white', fontFamily: "Montserrat, Medium", marginTop: "20px",  color: "blue" ,marginLeft:"-420%"}}
        >
          see more
          <span style={{ display: 'inline-block', fontSize: "14px", color: 'white', marginLeft: '10px', height: '20px', width: '20px', lineHeight: '20px', textAlign: 'center', verticalAlign: 'middle', color: "blue" }}>
            <i className="fas fa-arrow-right"></i>
          </span>
        </a>
        </div>
      ),
    },
  ];

  function Card({ card }) {
    return (
      
      <div className="card">
        {card.id != 2 ? (
          <>
            <div id="imgcard">
              {" "}
              <img src={card.image} alt={card.title} id="cardimage" />
            </div>
            <div id="textcard" style={{ width: "40%" }}>
              <h2 style={{ marginTop: "15px", textAlign:'center' ,fontSize:"28px",fontFamily:"Montserrat, SemiBold",marginLeft:"-20%"}}>{card.title}</h2>
              <p>{card.description}</p>
              <br />
              <p3 style={{ fontSize:"21px" ,fontFamily:"Montserrat, SemiBold"}}>
                {card.description_2}
              </p3>
              <button>{card.button}</button>
            </div>
          </>
        ) : (
          <>
          {/* <div style={{display:"flex", justifyContent:'space-around', width:'100%',objectFit:"cover"}}> */}
            <div id="textcard" style={{  }}>
              <h2 style={{  marginTop: "25px",fontSize:"28px",fontFamily:"Montserrat, SemiBold" }}>{card.title}</h2>
              <p1 style={{fontSize:"21px",fontFamily:"Montserrat, SemiBold"}}  >{card.description}</p1>
            
              <button style={{ marginTop: "10px" }}>{card.button}</button>
            </div>
            <div  style={{textAlign:"end"}}  id="imgcard" >
              {" "}
              <img style={{width:'74%'}} src={card.image} alt={card.title} id="cardimage" />
            </div>
            {/* </div> */}
          </>
        )}
      </div>
    );
  }

  function Cards() {
    return (

      <div className="cards-container">
        {cardData.map((card) => (
          <Card key={card.id} card={card} />
        ))}
      </div>
    );
  }

  //

  const Container = ({ text1, subtext, text2 }) => {
    const lineBreakText2 = text2.replace(/{<br>}/g, "<br/>");

    return (
      <div
        id="cards4container"
        style={{
          // maxWidth: "250px",
          display: 'flex',
          flex: 1,
          // padding: "10px",
          // border: "1px solid #ccc",
          textAlign: 'center',
          alignItems: 'center'
        }}
      >
        <div id="cards4container1">
          <h2 style={{ margin: 0, fontSize: "60px " }}>{text1}</h2>
          <p style={{ marginTop: "-22px", fontSize: "39px" }}>{subtext}</p>

        </div>
        <h2
          className="h2"
          dangerouslySetInnerHTML={{ __html: lineBreakText2 }}
          style={{
            fontSize: "20px",
            marginTop: "24px",
            margin: 0,
            textAlign: "center",
            alignItems: "center",
            marginLeft: "9px",
            fontFamily: "Montserrat, SemiBold"
          }}
        ></h2>
      </div>
    );
  };

  // const containerNumbers = [
  //   { text1: "1k", subtext: "+", text2: "Youth {<br>} Volunteeres" },
  //   { text1: "60", text2: "CPR {<br>} Certified Youth" },
  //   { text1: "30", text2: "Total years in {<br>} Community service" },
  //   {
  //     text1: "85",
  //     subtext: "%",
  //     text2: "High School {<br>} Students and more...",
  //   },
  // ];
  const main4cards = [
    { number: '1k+', text: 'Youth Volunteeres' },
    { number: 60, text: 'CPR Certified Youth' },
    { number: 30, text: 'Total years in Community service' },
    { number: '85%', text: 'High School Students and more...' },
  ];


  const [currentSlide, setCurrentSlide] = useState(0);
  const [gallerySlide, setGallerySlide] = useState(0);


  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    speed: 500,
    dots: false,
    arrows: false,
    centerMode: true,
    centerPadding: "0",
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };

  const settings2 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    speed: 500,
    dots: false,
    arrows: false,
    centerMode: true,
    centerPadding: "0",
    afterChange: (current) => {
      setGallerySlide(current);
    },
  };
  const settingsimag = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // 1 display at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,

  };

  const getGalleryImageContainerStyles = (index, gallerySlide) => {
    const scale = index === gallerySlide ? 1.3 : 1; // Increase scale for center image
    const transition = "transform 0.5s ease";

    return {
      display: "flex",
      justifyContent: "center",
      transform: `scale(${scale})`,
      transition: transition,
    };
  };

  const getImageWidth = (index) => {
    if (index === currentSlide) return "100%"; // Active center image
    if (index === currentSlide - 1 || index === currentSlide + 1) return "70%"; // Left or right images
    return "70%"; // Other images
  };
  const getImageWidthdown = (index) => {
    if (index === currentSlide) return "100%"; // Active center image
    if (index === currentSlide - 1 || index === currentSlide + 1) return "50%"; // Left or right images
    return "50%"; // Other images
  };



  const getImageContainerStyles = (index, currentSlide) => {
    const displayStyle =
      index === currentSlide + 1 ? "flex !important" : "inline-block";
    const justifyContentStyle =
      index === currentSlide + 1 ? "flex-end !important" : "initial";

    return {
      display: displayStyle,
      justifyContent: justifyContentStyle,
    };
  };

  // const Home = () => {

  const handleViewMoreClick = () => {
    setShowMoreImages(!showMoreImages);
  };

  // const history = useHistory();

  // const navigateBack = () => {
  //   history.goBack(); // This will navigate back to the previous page
  // };

  // const handleGoBackClick = () => {
  //   window.history.back();
  // };

  // const temple1 = "temple1"; // define the image URLs
  // const temple2 = "";
  // const temple = "";

  return (

    // style={{ overflow: "hidden" }}
    <div>
      <div>


        {/* <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <img
              src={YouthBridgeImage}
              alt="Young Generation"
              style={{
                display: "flex",
                alignItems: "center",
                height: "60px",
                paddingLeft: "40%",
                alignItems: "center",
                paddingTop: "3%",
              }}
            />
          </div>

          <nav style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
            <ul
              style={{
                display: "flex",
                listStyle: "none",
                margin: 0,
                padding: 0,
                marginLeft: "300px"
              }}
            >
              <li style={{ marginRight: "15px" }}>
                <a href="/">Home</a>
              </li>
              <li style={{ marginRight: "15px" }}>
                <a href="#about">About</a>
              </li>
              <li style={{ marginRight: "15px" }}>
                <a href="#Events">Events</a>
              </li>
              <li style={{ marginRight: "15px" }}>
                <a href="#Register">Register</a>
              </li>
             
              <li style={{ marginRight: "15px" }}>
                <a href="#Gallery">Gallery</a>
              </li>
            </ul>
          </nav>
        </div> */}


        <div style={{display:'flex',justifyContent:'space-around', width:'89%', gap:'10%' }}>
            <img
              src={YouthBridgeImage}
              alt="Young Generation"
              style={{
                // display: "flex",
                alignItems: "center",
                height: "60px",
                // paddingLeft: "40%",
                alignItems: "center",
                // paddingTop: "3%",
              }}
            />
      <div className={navClass} id="myTopnav">
        <a href="#home" className="active">Home</a>
        <a href="#about">About</a>
        <a href="#Events">Events</a>
        <a href="#Register">Register</a>
        <a href="#Gallery">Gallery</a>
        <a href="javascript:void(0);" className="icon" onClick={toggleNav}>
          <i className="fa fa-bars"></i>
        </a>
      </div>
      </div>

        {/* <div

          id="Homecontent"
          className="carousel-container"
          style={{
            backgroundImage: `url(${bgimages[currentImage]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",

            position: "relative",
           
          }}
          onClick={() => setCurrentImage((currentImage + 1) % bgimages.length)}
        > */}




        {/* const dotsContainerStyle = {
  position: 'absolute',
  bottom: '20px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};

const dotStyle = {
  height: '15px',
  width: '15px',
  margin: '0 5px',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  borderRadius: '50%',
  display: 'inline-block',
  cursor: 'pointer',
};

const activeDotStyle = {
  ...dotStyle,
  backgroundColor: 'rgba(255, 255, 255, 1)',
}; */}











        {/* <div id="Home1">
            <div id="Home11"> */}
        {/* 
            <img
              src={YouthBridgeImage}
              alt="Young Generation"
              style={{ maxWidth: "14%", height: "60px", borderRadius: "8px",marginTop:'-300px',marginLeft: "150px",top:"100px"}} /> */}

        {/* <div style={{ marginLeft: "22px", marginTop: "20px" }}></div>
              <h1 style={{ fontSize: "35px", fontFamily: "Montserrat, Bold", marginLeft: "30px" }}>
                Let's channel the vitality, intelligence,
                <br /> and influence of our young generation
                <br /> to create a brighter future.
              </h1>
              <p style={{ fontSize: "15px", marginTop: "10px", fontFamily: "Montserrat, Regular", marginLeft: "30px" }}>
                To establish a space at Balaji Temple that serves as a platform
                for the youth community,
                <br /> fostering a sense of inclusion and active engagement in
                shaping a more promising future.
              </p> */}
        {/* <div style={{ marginTop: "20px" }}>
              <button style={{ marginRight: "10px" }}>Join Today</button>
              <button style={{ color: "#6C2722", background: "white" }}>
                View Services
              </button>
            </div> */}
        {/* </div>
          </div>
        </div> */}








        <div
          id="Homecontent"
          className="carousel-container"
          style={{
            backgroundImage: `url(${bgimages[currentImage]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
          }}
          onClick={() => setCurrentImage((currentImage + 1) % bgimages.length)}
        >
          <div id="Home1">
            <div id="Home11">
              {/* 
          <img
            src={YouthBridgeImage}
            alt="Young Generation"
            style={{ maxWidth: "14%", height: "60px", borderRadius: "8px", marginTop: '-300px', marginLeft: '150px', top: '100px' }}
          /> */}
              <div style={{ marginLeft: "22px", marginTop: "20px" }}></div>
              <h1 style={{ fontSize: "35px", fontFamily: "Montserrat, Bold", marginLeft: "3px" }}>
                Let's channel the vitality, intelligence,
                <br /> and influence of our young generation
                <br /> to create a brighter future.
              </h1>
              <p style={{ fontSize: "15px", marginTop: "10px", fontFamily: "Montserrat, Regular", marginLeft: "4px" }}>
                To establish a space at Balaji Temple that serves as a platform
                for the youth community,
                <br /> fostering a sense of inclusion and active engagement in
                shaping a more promising future.
              </p>
              {/* <div style={{ marginTop: '20px' }}>
            <button style={{ marginRight: '10px' }}>Join Today</button>
            <button style={{ color: '#6C2722', background: 'white' }}>
              View Services
            </button>
          </div> */}
            </div>
          </div>
          <div className="dots-container" style={dotsContainerStyle}>
            {bgimages.map((_, index) => (
              <div
                key={index}
                className={`dot ${index === currentImage ? 'active' : ''}`}
                style={index === currentImage ? activeDotStyle : dotStyle}
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentImage(index);
                }}
              />
            ))}
          </div>
        </div>
        {/* );
}; */}




        <div id="temple_1" style={{ marginTop: "-13%" }}>
          <div id="temple_1_1">
            <h1
              style={{
                textAlign: "center",
                padding: "0% 0px 4%",
                fontSize: "29px",
              }}
            >
              <p style={{ marginBottom: "-37px", fontFamily: "Montserrat, Bold", fontSize: "28px" }}> TAKE ACTION NOW </p><br />
              <p style={{ marginBottom: "-22px", fontFamily: "Montserrat, SemiBold", fontSize: "24px" }}>  Unleash the creativity of our young talents to drive innovation </p>
            </h1>

            <Cards />

            <h1 className="thankstext"
              style={{
                fontSize: "28px",
                color: "#F5ECEC",
                textAlign: "center",
                padding: "10px",
                fontFamily: "Montserrat, SemiBold"
              }}
            >
              Thanks to our community supporters and partners
            </h1>
            <div class="container">
              <div class="cards">
                <a href="https://ezjobs.io/">
                  <img src={ezjobs_logo} />
                </a>
              </div>
              <div class="cards">
                <a href="https://pragmatiq.in/">
                  <img src={Pragmatiq_logo} />
                </a>
              </div>
              <div class="cards">
                <a href="https://www.volunteerlocal.com/">
                  <img src={volunteer_logo} />
                </a>
              </div>
            </div>
            <div
              className="card_2"
              id="about"
              style={{ width: "89%" }}
            >
              <div


                id="card_2_1"
                style={{
                  width: "400px",
                  marginBottom: "10px",
                  marginLeft: "35px",
                  display: "flex",
                }}
              >
                <img style={{ width: "120%" }} src={aboutus} />

              </div>
              <div id="card_2_2" >
                <div style={{ textAlign: "center" }}>
                  <h1 style={{ marginLeft: "-50px", fontSize: "38px", fontFamily: "Montserrat, Medium" }}>About us</h1>
                  <h2 style={{ fontSize: "25px", fontFamily: "Montserrat, SemiBold", marginLeft: "40px" }}>
                    A youth-driven community, by and for the youth
                  </h2>
                </div>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    marginLeft: "135px",
                    fontFamily: "Montserrat, Regular"
                  }}
                >
                  The Hindu Temple and Cultural Society of USA  Inc. (HTCS),
                  <br />Sri Venkateswara Temple (Balaji Mandir) and Community<br />
                  Center is a non-profit organization.
                  This religious <br />institution has been steadily
                  expanding over the last <br />several decades and today
                  boasts of a large devotee <br /> population from the
                  tri-state area as well other <br />parts of the country.
                </p>
              </div>
            </div>
            {/* <div id="cards4containermain" style={{ margin: "0% 4%  ", gap: '20px', height: "100%" }} >
              {containerNumbers.map((containerData, index) => (
                <Container
                  key={index}
                  className="container" // Add this line if Container accepts className
                  text1={containerData.text1}
                  subtext={containerData.subtext}
                  text2={containerData.text2}

                />
              ))}
            </div> */}


{/* <div className="cards4-row">
      {main4cards.map((card, index) => (
        <div key={index} className={`cards4 ${index === 3 ? 'cards4-extra-width' : ''}`}>
          <div className="cards4-number">
            {typeof card.number === 'string' && card.number.includes('+') ? (
              <>
                {card.number.slice(0, -1)}
                <span className="cards4-plus">+</span>
              </>
            ) : (
              card.number
            )}
          </div>
          <div className="cards4-text">{card.text}</div>
        </div>
      ))}
    </div> */}


<div className="cards4-row">
      {main4cards.map((card, index) => (
        <div key={index} className={`cards4 ${index === 0 ? 'cards4-first' : ''}`}>
          <div className="cards4-number">
            {typeof card.number === 'string' && card.number.includes('k') ? (
              <>
                {card.number.slice(0, -1)}
                <span className="cards4-plus">+</span>
              </>
            ) : (
              card.number
            )}
          </div>
          <div className="cards4-text">{card.text}</div>
        </div>
      ))}
    </div>

            <div
              className="container_2"
              id="Register"
              style={{ width: "950px" }}
            ></div>
            <div className="container_2">
              <div className="container_2_in">
                <h1>JOIN YOUTH GROUP</h1>
                <h2>Want to get involved in youth activities?</h2>
                <div className="container_2_1">
                  <div className="container_2_1_1">
                    The Youth Committee comprises of the Chair person,
                    <br /> the Vice Chair, and the committee members.
                    <br /> The Chair and the Vice Chair have to be Life
                    <br /> members at the temple and are appointed
                    <br /> by the HTCS Board for a 2-year term or more <br />
                    and the committee members are picked by the
                    <br /> Chair and Vice Chair.
                    <br /> Write to us at{" "}
                    {/* <a style={{ color: "blue" }}>
                    youth @venkateswaratemple.org
                  </a> */}
                  <div className="venkateshwaralink">
                    <a style={{ color: "blue",textDecoration:"none" }}
                      href="https://www.venkateswaratemple.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                     youth@Sri Venkateswara Temple.
                    </a>
                    </div>
                    
                    if you would like to get involved.
                  </div>
                  <div className="container_2_1_2">
                    <input type="text" name="name" value={user.name} onChange={(e) => setUser({ ...user, name: e.target.value })} placeholder="*Name" />
                    {errors.name && <p>{errors.name}</p>}
                    <input type="text" name="email" value={user.email} onChange={(e) => setUser({ ...user, email: e.target.value })} placeholder="*E-mail" />
                    {errors.email && <p>{errors.email}</p>}
                    <input type="text" name="phone" value={user.phone} onChange={(e) => setUser({ ...user, phone: e.target.value })} placeholder="*Mobile Number" />
                    {errors.phone && <p>{errors.phone}</p>}
                    <input type="text" name="zipcode" value={user.zipcode} onChange={(e) => setUser({ ...user, zipcode: e.target.value })} placeholder="*Zipcode" />
                    {errors.zipcode && <p>{errors.zipcode}</p>}
                    {/* <button
                    onClick={handleSubmit}
                    >Volunteer now!</button>  */}
                    <button className="volunteer-button" onClick={handleSubmit}>
                      Volunteer now!
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="carousel-container"
              id="Events"
              style={{ width:"90%" }}
              // width: "1450px"
            >
              <div style={{ textAlign: "center" }}>
                <p className="para"
                  style={{
                    fontSize: "22px",
                    fontWeight: "200",
                    margin: "0 0 0 5%",
                    fontFamily: "Montserrat, Regular",
                    maxWidth: "100%",
                    marginTop: "-10px"
                  }}
                >
                  Come back frequently to stay informed about our <br /> programs
                  and deepen your understanding.
                </p>
                <h1
                  style={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    margin: "5px",
                    fontFamily: "Montserrat, Bold",
                    marginTop: "18px",
                  }}
                >
                  Event List
                </h1>
              </div>
              {/* 
              <div className="carousel-container">
                <Slider {...settings} autoplay autoplaySpeed={2500}>
                  {images.map((image, index) => (
                    <div
                      key={index}
                      className="image-container"
                      style={getImageContainerStyles(index, currentSlide)}
                    >
                      <img
                        src={image}
                        alt={`Image ${index + 1}`}
                        style={{ width: getImageWidth(index) }}
                      />
                    </div>
                  ))}
                </Slider>
              </div> */}


              {/* <div className="carousel-container carousel-container-1">
              <Slider
                {...settingsimag}
                autoplay
                pauseOnHover={false}
                dots={false}
                arrows={false}
              >
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Image ${index}`}
                    style={{
                      border: "3px solid #efc36a",
                      borderRadius: "30px",
                      padding: "2px",
                      
                    }}
                  />
                ))}
              </Slider>
            </div> */}



              <div className="carousel-container">
                <div className="carousel-container carousel-container-1">
                  <Slider
                    {...settings}
                    autoplay
                    autoplaySpeed={3000}
                    pauseOnHover={false}
                  >
                    {images.map((image, index) =>
                      image != null ? (
                        <HashLink
                          key={index}
                          // to={`/Spring#section${index}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div key={index} className="image-container">
                            <img
                              src={image}
                              alt={image}
                            />
                          </div>
                        </HashLink>
                      ) : null
                    )}
                  </Slider>
                </div>
              </div>





            </div>

            {/*    <div className="carousel-container">
      <Slider {...settings} autoplay autoplaySpeed={1000}>
        {images.map((image, index) => (
          <div key={index} className="image-container">
            <img src={image} alt={`Image ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div> */}
            {/* <h3
            id="h3"
            style={{
              color: "#F5ECEC",
              textAlign: "center",
              fontSize: "30px",
              fontFamily: "Montserrat, Bold",
            }}
          >
            View all events →
          </h3> */}

            <div className="container_3">
              <div className="container_3_in">
                <h1>PVSA Award Winners - '20 - '21</h1>
                <div className="container_3_2">
                  <div className="container_3_2_sub">
                    <h3 style={{ marginTop: "25px" }}>Gold Medal</h3>
                    <p style={{ marginTop: "-10px" }}>Eshaan Debnath</p>
                    <p> Manasa Bandi</p>
                    <p>Rishith Vemireddy</p>
                    <p>Sreeprada Tadimella</p>
                    <p>Trishna Devi Rao</p>
                  </div>
                  <div className="container_3_2_sub">
                    <h3 style={{ marginTop: "25px" }}>Silver Medal</h3>
                    <p style={{ marginTop: "-10px" }} >Santosh</p>
                    <p> Sahana Radhakrishnan</p>
                    <p>Shashaank Narayanan</p>
                    <p>Roshan Dave</p>
                    <p>Pragadeshwar.K </p>
                    <p>Sarayu Cheemalapati</p>
                  </div>
                  <div className="container_3_2_sub">
                    <h3 style={{ marginTop: "25px" }}>Bronze Medal</h3>
                    <p style={{ marginTop: "-10px" }}>Anika Paidipalli</p>
                    <p>Sethuram Kamalakannan</p>
                    <p> Sidharth Ramanath</p>
                    <p>Thoshan Omprakash </p>
                    <p>Keshav Raghavan</p>
                    <p>Ganesh</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="container_4">
              <div className="container_4_in">
                <h1 style={{ fontFamily: "Montserrat, Bold", marginTop: "8px" }}>Collaborate & learn, Join us now! </h1>
                <p style={{ fontSize: "23px",  fontFamily: "Montserrat, Regular",textAlign:"center" }}>
                  Join us in empowering the youth by participating in temple
                  activities
                </p><br></br>
                <p style={{ fontSize: "23px",  marginTop: "-40px", fontFamily: "Montserrat, Regular",textAlign:"center" }}>and giving back to the community.</p>
                <p style={{ fontSize: "23px",  marginTop: "60px", fontFamily: "Montserrat, Regular",textAlign:"center" }} >The HTCS Youth Committee aims to create a long-term sustainable</p>
                <p style={{ fontSize: "23px",  marginTop: "-20px", fontFamily: "Montserrat, Regular",textAlign:"center" }}> platform for its objectives by engaging with the youth and
                  thereby</p>
                <p style={{ fontSize: "23px", marginTop: "-20px", fontFamily: "Montserrat, Regular" ,textAlign:"center"}}>
                  ensuring continuity of its enormous cause to society.
                </p>
              </div>
            </div>
            {/* <div
              className="Gallery"
              id="Gallery"
              style={{ width: "950px" }}
            ></div>
            <div className="Gallery">
              <h1
                style={{
                  fontSize: "40px",
                  fontWeight: "bold",
                  margin: "5px",
                  fontFamily: "Montserrat",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                Gallery
              </h1>
            </div>
            <div style={{ width: "80%", margin: "0 auto", marginTop: "5%" }}> */}
            {/* <Slider {...settings2} autoplay autoplaySpeed={1000}>
                {Gallery.map((image, index) => (
                  <div
                    key={index}
                    className="image-container"
                    style={{ width: getImageWidthdown(index) }}
                  >
                    <img
                      src={image}
                      alt={`Image ${index + 1}`}
                      style={{ width: "100%" }}
                    />
                  </div>
                ))}
              </Slider> */}
            {/* <Slider {...settings} autoplay autoplaySpeed={1000}  >
                  {Gallery.map((image, index) => (
                    <div 
                      key={index}
                      className="image-container"
                      style={getImageContainerStyles(index, currentSlide)}
                    >
                      <img
                        src={image}
                        alt={`Image ${index + 1}`}
                        style={{ width: getImageWidth(index)}}
                      />
                    </div>
                  ))}
                </Slider> */}


            {/* 
<div className="carousel-container carousel-container-1">
              <Slider
                {...settingsimag}
                autoplay
                pauseOnHover={false}
                dots={false}
                arrows={false}
              >
                {Gallery.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Image ${index}`}
                    style={{
                      border: "3px solid #efc36a",
                      borderRadius: "30px",
                      padding: "6px",
                    }}
                  />
                ))}
              </Slider>
            </div> */}





            {/* <div className="gallery-container">
                <div className="gallery-slider">
                  <Slider
                    {...settings}
                    autoplay
                    autoplaySpeed={3000}
                    pauseOnHover={false}
                  >
                    {Gallery.map((image, index) =>
                      image != null ? (
                        <HashLink
                          key={index}
                          // to={`/Spring#section${index}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div key={index} className="gallery-image-container">
                            <img
                              src={image}
                              alt={image}
                            />
                          </div>
                        </HashLink>
                      ) : null
                    )}
                  </Slider>
                </div>
              </div>





            </div> */}



            <div
              className="carousel-container"
              id="Gallery"
              style={{width:"90%" , marginTop: "-5%" }}
              // width: "1450px"
            >
              <div style={{ textAlign: "center" }}>

                <h1
                  style={{
                    fontSize: "40px",
                    fontWeight: "bold",
                    margin: "5px",
                    fontFamily: "Montserrat, Bold",
                    marginTop: "15px",
                  }}
                >
                  Gallery
                </h1>
              </div>
              {/* 
              <div className="carousel-container">
                <Slider {...settings} autoplay autoplaySpeed={2500}>
                  {images.map((image, index) => (
                    <div
                      key={index}
                      className="image-container"
                      style={getImageContainerStyles(index, currentSlide)}
                    >
                      <img
                        src={image}
                        alt={`Image ${index + 1}`}
                        style={{ width: getImageWidth(index) }}
                      />
                    </div>
                  ))}
                </Slider>
              </div> */}


              {/* <div className="carousel-container carousel-container-1">
              <Slider
                {...settingsimag}
                autoplay
                pauseOnHover={false}
                dots={false}
                arrows={false}
              >
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Image ${index}`}
                    style={{
                      border: "3px solid #efc36a",
                      borderRadius: "30px",
                      padding: "2px",
                      
                    }}
                  />
                ))}
              </Slider>
            </div> */}



              <div className="carousel-container">
                <div className="carousel-container carousel-container-1">
                  <Slider
                    {...settings}
                    autoplay
                    autoplaySpeed={3000}
                    pauseOnHover={false}
                  >
                    {Gallery.map((image, index) =>
                      image != null ? (
                        <HashLink
                          key={index}
                          // to={`/Spring#section${index}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div key={index} className="image-container">
                            <img
                              src={image}
                              alt={image}
                            />
                          </div>
                        </HashLink>
                      ) : null
                    )}
                  </Slider>
                </div>
              </div>





            </div>



            <div className="footer">
              <h1
                style={{
                  color: "#F5ECEC",
                  fontFamily: "Montserrat, Bold",
                  fontSize: "20px",
                  marginTop: "5%",
                }}
              >
                Contact Information
              </h1>
              <div className="footer1">
                <img style={{ width: "18px", height: "18px", marginTop: "5px" }} src={call} />
                <p style={{ fontSize: "20px" }}>+1 (908) 725-4477</p>
              </div>
              <div className="footer1">
                <img style={{ width: "16px", height: "16px", marginTop: "7px" }} src={mail} />
                <p style={{ fontSize: "20px", fontFamily: "Montserrat, Regular" }}>youth@venkateswaratemple.org</p>
              </div>

              <p
                style={{
                  color: "#F5ECEC",
                  fontFamily: "Montserrat, Medium",
                  width: "60%",
                  fontSize: "22px",
                }}
                id="footerp"
              >
                Youth Bridge is the portal for the youth at  <br />  Sri Venkateswara
                Temple, Bridgewater, NJ.
              </p>
              {/* <div id="imageslider">
              <ImageSlider modifiedAnimation/>
              <ImageSlider/>
              <ImageSlider modifiedAnimation />
              <ImageSlider/>
              <ImageSlider modifiedAnimation/>
             
            </div> */}
            </div>

            <p
              style={{ textAlign: "center", fontSize: "11px", marginTop: "6%" }}
              id="bottompage"
            >
              Copyright 1998-2024 Sri Venkateswara Temple (Balaji Mandir) &
              Community Center.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
